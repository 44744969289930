import Color from 'color';
import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

const brand = {
  font: process.env.GATSBY_BRAND_FONT,
  header: process.env.GATSBY_BRAND_COLOR_HEADER,
  icons: process.env.GATSBY_BRAND_COLOR_ICONS,
  primary: process.env.GATSBY_BRAND_COLOR_PRIMARY,
  secondary: process.env.GATSBY_BRAND_COLOR_SECONDARY,
};

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      light: Color(brand.primary).lighten(0.5).hex(),
      main: brand.primary,
      dark: Color(brand.primary).darken(0.5).hex(),
    },
    secondary: {
      light: Color(brand.secondary).lighten(0.5).hex(),
      main: brand.secondary,
      dark: Color(brand.secondary).darken(0.5).hex(),
    },
    common: {
      black: '#000',
      white: '#fff',
      header: brand.header,
      icons: brand.icons,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
    
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      brand.font,
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(',')
  },
});

export default theme;
