// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-card-js": () => import("./../../../src/templates/card.js" /* webpackChunkName: "component---src-templates-card-js" */),
  "component---src-templates-flyer-js": () => import("./../../../src/templates/flyer.js" /* webpackChunkName: "component---src-templates-flyer-js" */),
  "component---src-templates-mobile-js": () => import("./../../../src/templates/mobile.js" /* webpackChunkName: "component---src-templates-mobile-js" */)
}

