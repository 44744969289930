import { ApolloClient } from 'apollo-client';
import fetch from 'isomorphic-fetch';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';

const cache = new InMemoryCache();

const link = createHttpLink({
  uri: 'https://sin-volante.locki.app/'
});

export const client = new ApolloClient({
  cache,
  fetch,
  link
})